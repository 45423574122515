// import React, { useState } from 'react'

// import PDFViewer from 'pdf-viewer-reactjs'
// import { Document, Page } from 'react-pdf'

// const ExamplePDFViewer = () => {
//     const [numPages, setNumPages] = useState();
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//     return (
//     <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
//         <iframe
//             title="PDF Viewer"
//             src={`https://docs.google.com/gview?url=${"https://forbackend.s3.amazonaws.com/uploads/17146653786421714665378294_disclaimers-merged.pdf"}&embedded=true`}
           
//             width="100%"
//             height="650px"
//           />
//     </div>  

//     )
// }

// export default ExamplePDFViewer

import React, { useState } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { Document, Page } from 'react-pdf';

const ExamplePDFViewer = () => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <header >
        <img
          src="https://rival.finance/wp-content/uploads/2024/01/Group-1171278535-1.svg"
          alt="Logo"
          style={{ height: '40px', marginRight: '10px' }}
        />
      </header>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
        <iframe
          title="PDF Viewer"
          src={`https://docs.google.com/gview?url=${"https://forbackend.s3.amazonaws.com/uploads/17146653786421714665378294_disclaimers-merged.pdf"}&embedded=true`}
          width="100%"
          height="650px"
        />
      </div>
    </div>
  );
}

export default ExamplePDFViewer;
